import $ from 'cash-dom';
import Element from '../lib/Element';

export default class XlibExtendableElement extends Element {
    init() {
        this.triggers = this.ref.find('[is-extendable-trigger]');
        this.targets = {};

        this.ref.find('[is-extendable-target]').map((index, el) => {
            el = $(el);
            this.targets[el.attr('is-extendable-target')] = el;
        });
    }

    initListeners() {
        this.triggers.on('click.extendable', e => this.toggle(e.target));
    }

    toggle(el) {
        el = $(el);
        const index = el.attr('is-extendable-trigger');

        if (!this.targets[index]) {
            return;
        }

        if (this.isExtended(el, index)) {
            this.close(el, index);
            return false;
        }
        this.open(el, index);
        return false;
    }

    open(el, index) {
        el.attr('is-open', 'true');
        this.targets[index].css('display', 'block');
    }

    close(el, index) {
        el.attr('is-open', 'false');
        this.targets[index].css('display', 'none');
    }

    isExtended(el, index) {
        return el.is("[is-open='true']");
    }
}
