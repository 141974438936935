import 'jquery';

import {Element} from '../../../xlib/js/xlib';
import FormUtils from "plugins/xlib/js/lib/FormUtils";

export default class NewsletterElement extends Element {
    init()
    {
        this.categoryFilters = $('[data-target-category-term]', this.ref);
        this.allfilterTargets = $('[data-category-term]', this.ref);
        this.resetFilterButton = $('.reset-filter', this.ref);
        this.selectAllNewslettersButton = $('.select-all-newsletters', this.ref);
        this.newsletterTeaserElements = $('[data-target-nss-post]', this.ref);
        this.removeNewsletterButtons = $('#NL_List .clearitem');
        this.nssForm = $('[name="nss-form"]', this.ref);
        this.emailField = $('[name="email"]', this.ref);
        this.submitButton = $('.is_submit_button', this.nssForm);
        this.errorWrapper = $('.error-wrapper', this.ref);
        this.showAfter = $('.show-after-submit', this.ref);
    }

    initListeners()
    {
        this.categoryFilters.on('click', () => {
            this.updateFilter();
        });
        this.resetFilterButton.on('click', () => {
            this.resetFilter();
        });

        this.newsletterTeaserElements.on('click', (event) => {
            if(this.isDisabled(event.currentTarget)) {
                return;
            }

            const postId = event.currentTarget.getAttribute('data-target-nss-post');
            this.toggleNewsletter(postId);
        });

        this.selectAllNewslettersButton.on('click', () => {
            this.selectAllVisible();
        });

        this.removeNewsletterButtons.on('click', (event) => {
            const postId = event.currentTarget.closest('[data-nss-post]').getAttribute('data-nss-post');
            this.toggleNewsletter(postId);
        });

        this.nssForm.on('submit', (event) => {
            event.preventDefault();
            this.submitNewsletter(event.currentTarget);
        });

        $('input[name="error_url"]', this.form).remove();
        $('input[name="success_url"]', this.form).remove();

        this.showAfter.hide();
    }

    updateFilter()
    {
        let filterSelected = [];

        this.allfilterTargets.hide();

        this.categoryFilters.each((idx, filter) => {
            if (filter.checked) {
                const filterTermId = filter.getAttribute('data-target-category-term');
                const matchingFilterTargets = $(`[data-category-term*="${filterTermId}"]`, this.ref);
                matchingFilterTargets.show();
                filterSelected.push(filter);
            }
        });

        if (filterSelected.length) {
            this.resetFilterButton.show();
        } else {
            this.resetFilterButton.hide();
            this.allfilterTargets.show();
        }

        if (filterSelected.length === 1) {
            const filterTitle = filterSelected[0].parentElement.textContent.trim();
            this.selectAllNewslettersButton[0].innerHTML = `Alle Ratgeber zu ${ filterTitle } auswählen`;
        } else {
            this.selectAllNewslettersButton[0].innerHTML = 'Alle auswählen';
        }
    }

    resetFilter()
    {
        this.categoryFilters.each((idx, filter) => {
            filter.checked = false;
        });

        this.updateFilter();
        this.resetFilterButton.hide();
    }

    toggleNewsletter(postId)
    {
        const newsletterInputElement = $(`[name="newsletter_id[]"][value="${postId}"]`, this.ref);
        const wasSelected = newsletterInputElement.length > 0;
        const newsletterElement = $(`[data-target-nss-post="${postId}"]`, this.ref);
        const newsletterListElement = $(`[data-nss-post="${postId}"]`, this.ref);

        if (wasSelected) {
            newsletterInputElement.remove();
            newsletterElement.removeClass('newsletter-selected');
            newsletterListElement.hide();
        } else {
            this.nssForm.append(`<input type="hidden" name="newsletter_id[]" value="${postId}">`);
            newsletterElement.addClass('newsletter-selected');
            newsletterListElement.show();
        }

    }

    selectAllVisible()
    {
        this.newsletterTeaserElements
            .filter(':visible')
            .filter(':not([data-disabled])')
            .each((idx, newsletterTeaserElement) => {
            const postId = newsletterTeaserElement.getAttribute('data-target-nss-post');
            const isSelected = $(`[name="newsletter_id[]"][value="${postId}"]`, this.ref).length > 0;
            if (!isSelected) {
                this.toggleNewsletter(postId);
            }
        });
    }

    translate(text)
    {
        if (this.config && this.config.translations && this.config.translations[text]) {
            return this.config.translations[text];
        }

        return text;
    }

    submitNewsletter(newsletterForm)
    {
        this.errorWrapper[0].innerHTML = '';

        const submitPromise = FormUtils.submitFormUsingAjax(newsletterForm, 'json');
        submitPromise.then((response) => {
            this.redoCaptcha();

            if (!response.data || !response.data.result_code) {
                return;
            }

            let result_code = response.data.result_code;
            result_code = result_code.replace(/<br\/>|br\//img, "\n");
            let results = result_code.split("\n").filter(v => !!v);

            let textParts = [];
            for (let i = 0; i < results.length; i++) {
                const text = this.translate(results[i]);
                textParts.push(text);
            }
            const text = textParts.join("<br />");

            if (result_code === 'subscription_successful') {
                this.errorWrapper[0].innerHTML = `<div class="success" style="font-weight: bold;">${text}</div>`;
                this.deselectAllNewsletter();
                this.showFurtherRegistrationButton();

                response.data?.cookies?.forEach(cookie => setCookie(cookie, 'subscribed', 7));
            } else {
                this.errorWrapper[0].innerHTML = `<div class="error" style="color: red;">${text}</div>`;
            }
        });
    }

    deselectAllNewsletter()
    {
        const selectedNewsletters = $('[name="newsletter_id[]"]', this.ref);
        selectedNewsletters.each((idx, elem) => {
            const inputEl = $(elem);
            const newsletterId = inputEl.val();
            this.toggleNewsletter(newsletterId);
        });
    }

    showFurtherRegistrationButton() {
        this.showAfter.show();
    }

    redoCaptcha()
    {
        if (this.nssForm[0].FriendlyCaptchaAdapter) {
            this.nssForm[0].FriendlyCaptchaAdapter.redoCaptcha();
        }
    }

    isDisabled(node) {
        return node.getAttribute('data-disabled');
    }
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
