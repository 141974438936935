import 'jquery';

import {Element} from '../../../xlib/js/xlib';

export default class RefreshElement extends Element {
    initListeners()
    {
        $(this.ref).on('click', e => {
            e.preventDefault();
            window.location.reload();
        });
    }
}
