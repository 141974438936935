import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'jquery';
import 'bootstrap';

import '../shared/custom';
import '../shared/remove-noscript';

import Application from 'plugins/xlib/js/lib/Application';
import NewsletterElement from 'plugins/xlib-reichweiten/js/elements/Newsletter';
import SearchResultsElement from 'plugins/xlib-reichweiten/js/elements/SearchResults';
import HeaderElement from 'plugins/xlib-reichweiten/js/elements/Header';
import NewsletterPluginForm from 'plugins/xlib-reichweiten/js/elements/NewsletterPluginForm';
import RefreshElement from 'plugins/xlib-reichweiten/js/elements/Refresh';

Application.configure({
    Elements: {
        'is-element-newsletter': NewsletterElement,
        'is-element-search-results': SearchResultsElement,
        'is-element-header': HeaderElement,
        'is-element-newsletter-plugin-form': NewsletterPluginForm,
        'is-element-refresh': RefreshElement,
    }
});
Application.start();
