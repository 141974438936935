import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import DebouncePromise from 'awesome-debounce-promise';
import _get from 'lodash/get';
import utils from 'plugins/xlib/js/lib/Util';

export class SuggestionsList extends React.Component {
    static defaultProps = {
        suggestRef: React.createRef()
    };

    ref = this.props.suggestRef;

    render() {
        const {
            id, prefix, list, selected, onClick, isOpen
        } = this.props;

        const listClass = classnames(prefix + 'list', isOpen ? 'is-open' : null);

        return (
            <ul className={listClass} id={`${id}-list`} role="listbox" tabIndex="-1" aria-hidden={!isOpen} ref={this.ref}>
                {list.map((item, i) => {
                    const isSelected = selected === i;
                    const itemClass = classnames(prefix + 'list-item', isSelected ? 'selected' : null);
                    const listItem = (
                        <li
                            className={itemClass}
                            id={`${id}-${i}`}
                            aria-selected={isSelected}
                            role="option"
                            onClick={onClick.bind(this, item)}
                            key={i}
                        >
                            {item}

                        </li>
                    );

                    i++;

                    return listItem;
                })}
            </ul>
        );
    }
}

export class Suggestions extends React.Component {
    static defaultProps = {
        minlength: 2,
        prefix: 'suggestions-',
        autosubmit: false,
        context: 'meta'
    }

    id = this.props.id ? this.props.id : utils.getRandomString();

    ref = React.createRef();

    suggestRef = React.createRef();

    state = {
        value: this.props.value || '',
        list: [],
        selected: -1,
        fetch: true,
        isOpen: false
    }

    debouncedFetchSuggests = DebouncePromise(this.fetchSuggests.bind(this), 300)

    /**
     * @param {string} value
     * @param {MouseEvent} event
     */
    handleSelect = async (value, event) => {
        const { autosubmit } = this.props;
        this.ref.current.focus();
        this.setState({
            value: value,
            isOpen: false,
            fetch: false
        }, () => {
            if (autosubmit) {
                this.ref.current.closest('form').submit();
            }
        });
    }

    /**
     * @param {KeyboardEvent} event
     */
    handleChange = async (event) => {
        const { value } = event.target;
        const { minlength } = this.props;

        this.setState({
            value: value,
            isOpen: value.length >= minlength
        });
    }

    /**
     * @param {KeyboardEvent} event
     */
    handleKeyUp = (event) => {
        const { autosubmit } = this.props;
        const { key } = event;

        this.setState((state) => {
            if (key === 'ArrowDown' && state.selected < (state.list.length - 1)) {
                return {
                    selected: ++state.selected,
                    isOpen: true
                };
            } if (key === 'ArrowUp' && state.selected >= 0) {
                return {
                    selected: --state.selected,
                    isOpen: state.selected >= 0
                };
            } if (key === 'Escape' && state.isOpen) {
                return {
                    selected: -1,
                    isOpen: false
                };
            } if ((key === 'Enter' || key === 'Tab') && state.selected >= 0) {
                const listItem = state.list[state.selected];

                return {
                    selected: -1,
                    value: listItem,
                    isOpen: false
                };
            }

            return {};
        }, () => {
            if (key === 'Enter' && autosubmit) {
                this.ref.current.closest('form').submit();
            }
        });
    }

    /**
     * @param {KeyboardEvent} event
     */
    handleKeyDown = (event) => {
        const {
            value, selected, list, isOpen
        } = this.state;
        const { key } = event;

        if (
            (key === 'ArrowDown' && selected < (list.length - 1))
            || (key === 'ArrowUp' && selected >= 0)
            || (key === 'Escape' && isOpen)
            || (key === 'Backspace' && value.length === 0)
            || ((key === 'Enter' || key === 'Tab') && selected >= 0)
        ) {
            event.preventDefault();
            event.stopPropagation();
        } else if (key === 'Tab' && isOpen && selected === -1) {
            // We want the Tab key to do it's normal thing here and focus on the next field so we
            // need to set the state here because keyUp will not trigger on this field anymore
            this.setState({
                selected: -1,
                isOpen: false
            });
        }
    }

    /**
     * @param {Event} event
     */
    handleBlur = (event) => {
        // Catch if the user clicks on a suggest-item since this will also trigger a blur
        const relTarget = event.relatedTarget || document.activeElement;

        if (this.suggestRef.current === relTarget) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            this.setState({
                isOpen: false
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { minlength } = this.props;
        const { value, fetch } = this.state;

        if (value !== prevState.value && value.length >= minlength && fetch) {
            this.debouncedFetchSuggests(value).then((result) => {
                this.setState({
                    list: result,
                    selected: -1,
                    isOpen: result.length > 0,
                    fetch: true
                });
            });
        }
    }

    /**
     * @param {string} newState
     * @returns {Promise<string[]>}
     */
    fetchSuggests(prefix) {
        const { context, path } = this.props;
        const api = window.location.protocol + '//' + window.location.host + '/api/xlib/suggestions';
        const tenant = path.match(/\/v\d(\/elasticApi)?\/(.*_.*)\/.*\/search\/suggest/);

        const url = new URL(api);
        url.searchParams.append('prefix', prefix);
        url.searchParams.append('tenant', tenant[2]);

        return fetch(url).then((response) => {
            if (response.ok) return response.json();

            throw new Error(response.statusText);
        }).then((json) => {
            const options = _get(json, 'suggest.' + context + '-suggest[0].options', []);

            return options.map((option) => option.text);
        });
    }

    render() {
        const {
            prefix,
            className,
            placeholder,
            required,
            name,
            type
        } = this.props;
        const {
            value, list, selected, isOpen
        } = this.state;

        return (
            <>
                <input
                    type={type}
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    required={required}
                    className={className}
                    autoComplete="off"
                    aria-autocomplete="list"
                    aria-owns={`${this.id}-list`}
                    aria-haspopup={isOpen}
                    aria-expanded={isOpen}
                    aria-activedescendant={isOpen ? `${this.id}-${selected}` : false}
                    onKeyDown={this.handleKeyDown}
                    onKeyUp={this.handleKeyUp}
                    onInput={this.handleChange}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    ref={this.ref}
                />
                <SuggestionsList
                    id={this.id}
                    isOpen={isOpen}
                    list={list}
                    selected={selected}
                    prefix={prefix}
                    onClick={this.handleSelect}
                    suggestRef={this.suggestRef}
                />
            </>
        );
    }
}

export default class SuggestionsModule {
    static NAME = 'suggestions'

    static DEFAULTS = {
        prefix: Suggestions.defaultProps.prefix
    }

    /**
     * @param {HTMLElement} element
     * @param {*} options
     */
    constructor(options = {}, element) {
        this.element = element[0];
        this.options = { ...SuggestionsModule.DEFAULTS, ...options };

        Object.assign(this.options, {
            id: this.element.id ? this.element.id : undefined,
            name: this.element.name ? this.element.name : undefined,
            type: this.element.type ? this.element.type : undefined,
            value: this.element.value ? this.element.value : undefined,
            className: classnames(this.options.prefix + 'input', [...this.element.classList]),
            placeholder: this.element.placeholder ? this.element.placeholder : undefined,
            required: this.element.required ? this.element.required : undefined
        });

        const parent = this.element.parentNode;
        const container = document.createElement('div');
        container.classList.add(this.options.prefix + 'container');
        parent.insertBefore(container, this.element);
        parent.removeChild(this.element);

        ReactDOM.render(<Suggestions {...this.options} />, container);
    }

    /**
     * Initializes all Suggestions-modules on the page
     */
    static initAll() {
        utils.initModule(SuggestionsModule.NAME, (element, config, name) => {
            const suggestions = new SuggestionsModule(element, config);
        });
    }
}
