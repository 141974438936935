export default class WpMediaLibrary
{

    /**
     * requirements in PHP:
     * add_action('admin_enqueue_scripts', 'wp_enqueue_media');
     *
     * example usage HTML:
     *      <div class="js_image_library_wrapper">
     *          <label for="cover_image_button">Cover Bild</label> <br/>
     *          <div>
     *              <img class="js_image_preview"
     *                  src="<?= $coverImagePost['url'] ?? '' ?>"
     *                   alt="<?= $coverImagePost['alt'] ?? '' ?>"
     *                   title="<?= $coverImagePost['title'] ?? '' ?>"
     *                   width="<?= $coverImagePost['width'] ?? '300' ?>"
     *              >
     *          </div>
     *          <input type="button" class="js_select_image_button" value="Bild auswählen">
     *          <input type="hidden" class="js_image_id" name="coverImageId" value="<?= $coverImageId ?>">
     *      </div>
     *
     *
     * example usage JavaScript:
     *      import {WpMediaLibrary} from 'plugins/xlib/js/xlib.js';
     *
     *      document.addEventListener('DOMContentLoaded', function() {
     *          WpMediaLibrary.bindImageLibraryPicker();
     *      });
     *
     */
    static bindImageLibraryPicker() {
        const mediaLibraryWrappers = document.querySelectorAll('.js_image_library_wrapper');

        mediaLibraryWrappers.forEach((mediaLibraryWrapper) => {
            const imageButton = mediaLibraryWrapper.querySelector('.js_select_image_button');
            const imageInput = mediaLibraryWrapper.querySelector('.js_image_id');
            const imagePreview = mediaLibraryWrapper.querySelector('.js_image_preview');
            const noImagePlaceholder = mediaLibraryWrapper.querySelector('.js_no_image_placeholder');
            const fileSize = mediaLibraryWrapper.querySelector('.js_file_size');

            if (!imageButton) {
                return;
            }

            const wpMediaLibrary = new WpMediaLibrary();

            imageButton.addEventListener('click', (event) => {
                event.preventDefault();

                wpMediaLibrary.imageLibraryPicker({
                    currentValue: imageInput.value ?? '',
                }, function(data) {
                    console.log(data);
                    imageInput.value = data[0].id;
                    imagePreview.src = data[0].url;
                    imagePreview.style.maxWidth = '100%';
                    noImagePlaceholder.innerHtml = data[0].filename ?? '';
                    fileSize.innerHTML = data[0].file_size_string ?? '';
                });
            });
        });
    }

    imageLibraryPicker(options, callback) {
        console.log('imageLibraryPicker');
        if(this.wpMediaFrame){
            this.wpMediaFrame.open();
            return;
        }

        options = (typeof options === 'object') ? options : {};

        // Predefined settings
        const defaultOptions = {
            title: 'Bild auswählen',
            button: {
                text: 'Bild auswählen'
            },
            fileType: 'image',
            multiple: false,
        };

        options = { ...defaultOptions, ...options };

        // Define this.wpMediaFrame as wp.media object
        this.wpMediaFrame = wp.media(options);

        this.wpMediaFrame.on('open', () => {
            console.log('wpMediaFrame open');
            // On open, get the id from the hidden input
            // and select the appropiate images in the media manager
            const selection =  this.wpMediaFrame.state().get('selection');
            const ids = options.currentValue.split(',');

            ids.forEach((id) => {
                const attachment = wp.media.attachment(id);
                attachment.fetch();
                selection.add( attachment ? [ attachment ] : [] );
            });
        });

        this.wpMediaFrame.on('select', () => {
            // On close, get selections and save to the hidden input
            // plus other AJAX stuff to refresh the image preview
            const selection =  this.wpMediaFrame.state().get('selection');
            const files = [];

            selection.each((attachment) => {
                files.push({
                    id: attachment.attributes.id,
                    filename: attachment.attributes.filename,
                    url: attachment.attributes.url,
                    type: attachment.attributes.type,
                    subtype: attachment.attributes.subtype,
                    sizes: attachment.attributes.sizes,
                    file_size_string: attachment.attributes.filesizeHumanReadable,
                });
            });

            callback(files);
        });

        this.wpMediaFrame.open();
    }
}

