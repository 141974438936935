import axios from 'axios';

export default class FormUtils
{
    /**
     * @param {Element} form
     * @param {string} responseType
     * @returns {Promise}
     */
    static submitFormUsingAjax(form, responseType='json')
    {
        const formData = new FormData(form);
        const formAction = form.getAttribute('action') || '/';
        const formMethod = form.getAttribute('method') || 'get';

        return  axios({
            method: formMethod,
            url: formAction,
            data: formData,
            responseType: responseType,
        });
    }
}


