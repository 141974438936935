import Element from './lib/Element';
import ElementExtendable from './elements/Extendable';
import CookieConsent from './elements/CookieConsent';
import SuggestionsModule from './elements/Suggestions';
import InputTagsModule from './elements/InputTags';
import Application from './lib/Application';
import WpMediaLibrary from './lib/WpMediaLibrary.js';

export {
    Element,
    ElementExtendable,
    CookieConsent,
    SuggestionsModule as Suggestions,
    InputTagsModule as InputTags,
    Application,
    WpMediaLibrary,
};
