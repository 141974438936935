import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import _dropRight from 'lodash/dropRight';
import utils from '../lib/Util';
import { Suggestions, SuggestionsList } from './Suggestions';

class InputTagsTag extends React.Component {
    static defaultProps = {
        value: '',
        highlight: false,
        onClick: () => {}
    }

    render() {
        const {
            value, prefix, highlight, onClick
        } = this.props;

        const className = classnames(prefix + 'tag', highlight ? prefix + 'tag--highlight' : null);

        return (
            <div className={className}>
                {value}
                <button type="button" className={prefix + 'tag-remove'} onClick={() => onClick(value)} />
            </div>
        );
    }
}

export class InputTags extends Suggestions {
    static defaultProps = ({ ...Suggestions.defaultProps, prefix: 'inputtags-',
        freetext: false,
        initialValue: ''})

    state = {
        value: '',
        list: [],
        tags: this.props.initialValue.length > 0 ? utils.stringToArray(this.props.initialValue) : [],
        selected: -1,
        isOpen: false,
        willDelete: false,
        isFocused: false,
        fetch: true
    }

    /**
     * @param {string} value
     * @param {MouseEvent} event
     */
    handleSelect = async (value, event) => {
        this.ref.current.focus();
        this.setState((state) => {
            let newTags = [...state.tags];

            if (!state.tags.includes(value)) {
                newTags = [...state.tags, value];
            }

            return {
                value: '',
                tags: newTags,
                isOpen: false,
                fetch: false
            };
        });
    }

    handleRemove = (value) => {
        this.setState((state) => ({
            tags: _dropRight(state.tags, 1)
        }));
    }

    /**
     * @param {KeyboardEvent} event
     */
    handleKeyUp = (event) => {
        const { autosubmit, freetext } = this.props;
        const { key } = event;

        this.setState((state) => {
            let newState = {};

            if (key === 'ArrowDown' && state.selected < (state.list.length - 1)) {
                newState = {
                    selected: ++state.selected,
                    isOpen: true
                };
            } else if (key === 'ArrowUp' && state.selected >= 0) {
                newState = {
                    selected: --state.selected,
                    isOpen: state.selected >= 0
                };
            } else if (key === 'Escape' && state.willDelete) {
                newState = {
                    willDelete: false
                };
            } else if (key === 'Escape' && state.isOpen) {
                newState = {
                    selected: -1,
                    isOpen: false
                };
            } else if (key === 'Backspace' && state.value.length === 0 && !state.willDelete) {
                newState = {
                    willDelete: true
                };
            } else if (key === 'Backspace' && state.value.length === 0 && state.willDelete) {
                newState = {
                    willDelete: false,
                    tags: _dropRight(state.tags, 1)
                };
            } else if ((key === 'Enter' || key === 'Tab') && (freetext || !freetext && state.selected >= 0)) {
                let value = '';
                let newTags = [...state.tags];

                if (freetext && state.selected === -1) {
                    value = state.value;
                } else {
                    value = state.list[state.selected];
                }

                if (!state.tags.includes(value)) {
                    newTags = [...state.tags, value];
                }

                newState = {
                    selected: -1,
                    tags: newTags,
                    value: '',
                    isOpen: false
                };
            }

            return newState;
        }, () => {
            if (key === 'Enter' && autosubmit) {
                this.ref.current.closest('form').submit();
            }
        });
    }

    handleFocus = (event) => {
        this.setState({
            isFocused: true
        });
    }

    handleBlur = (event) => {
        // Catch if the user clicks on a suggest-item since this will also trigger a blur
        if (this.suggestRef.current === event.relatedTarget) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            this.setState({
                isOpen: false,
                isFocused: false
            });
        }
    }

    /**
     * @param {KeyboardEvent} event
     */
    handleKeyDown = (event) => {
        const { freetext } = this.props;
        const {
            value, selected, list, isOpen
        } = this.state;
        const { key } = event;

        if (
            (key === 'ArrowDown' && selected < (list.length - 1))
            || (key === 'ArrowUp' && selected >= 0)
            || (key === 'Escape' && isOpen)
            || (key === 'Backspace' && value.length === 0)
            || ((key === 'Enter' || key === 'Tab') && (freetext || !freetext && selected >= 0))
        ) {
            event.preventDefault();
            event.stopPropagation();
        } else if (key === 'Tab' && !freetext && selected === -1) {
            this.setState({
                value: ''
            });
        }
    }

    render() {
        const {
            prefix,
            className,
            placeholder,
            name,
            type
        } = this.props;
        const {
            value, list, tags, selected, isOpen, willDelete, isFocused
        } = this.state;
        const fakeInputClass = classnames(className, isFocused ? 'has-focus' : null);

        return (
            <>
                <div className={fakeInputClass}>
                    {tags.map((tag, i) => <InputTagsTag value={tag} prefix={prefix} highlight={i === tags.length - 1 && willDelete} onClick={this.handleRemove} key={i} />)}
                    <input
                        className={prefix + 'input'}
                        type={type}
                        value={value}
                        autoComplete="off"
                        placeholder={placeholder}
                        aria-autocomplete="list"
                        aria-owns={`${this.id}-list`}
                        aria-haspopup={isOpen}
                        aria-expanded={isOpen}
                        aria-activedescendant={isOpen ? `${this.id}-${selected}` : false}
                        onKeyDown={this.handleKeyDown}
                        onKeyUp={this.handleKeyUp}
                        onInput={this.handleChange}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        ref={this.ref}
                    />
                </div>
                <input value={tags.join(',')} type="hidden" name={name} />
                <SuggestionsList
                    id={this.id}
                    isOpen={isOpen}
                    list={list}
                    selected={selected}
                    prefix={prefix}
                    onClick={this.handleSelect}
                    ref={this.suggestRef}
                />
            </>
        );
    }
}

export default class InputTagsModule {
    static NAME = 'inputtags'

    static DEFAULTS = {
        prefix: InputTags.defaultProps.prefix,
        component: InputTags
    }

    /**
     * @param {HTMLElement} element
     * @param {*} options
     */
    constructor(element, options = {}) {
        this.element = element;
        this.options = { ...InputTagsModule.DEFAULTS, ...options};

        Object.assign(this.options, {
            id: this.element.id ? this.element.id : undefined,
            name: this.element.name ? this.element.name : undefined,
            type: this.element.type ? this.element.type : undefined,
            initialValue: this.element.value ? this.element.value : undefined,
            className: classnames(this.options.prefix + 'fakeinput', [...this.element.classList]),
            placeholder: this.element.placeholder ? this.element.placeholder : undefined
        });

        const parent = this.element.parentNode;
        const container = document.createElement('div');
        container.classList.add(this.options.prefix + 'container');
        parent.insertBefore(container, this.element);
        parent.removeChild(this.element);

        ReactDOM.render(<this.options.component {...this.options} />, container);
    }

    /**
     * Initializes all Suggestions-modules on the page
     */
    static initAll() {
        utils.initModule(InputTagsModule.NAME, (element, config, name) => {
            const suggestions = new InputTagsModule(element, config);
        });
    }
}
