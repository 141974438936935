import $ from 'cash-dom';
import Util from '../lib/Util';

export default class CookieConsent {
    static NAME = 'cookieconsent';

    static DEFAULTS = {
        prefix: 'cookieconsent-'
    };

    /**
     * @param {HTMLElement} element
     * @param options
     */
    constructor(element, options) {
        this.element = element;


        this.options = Object.assign({}, CookieConsent.DEFAULTS, options);
    }

    init() {
        this.cookieConsentBox = $('.cookie-consent-box');

        var cookie = Util.getCookie('cookieConsentAccepted');

        if (cookie == '') {
            this.showCookieConsentBox();
        }
    }

    showCookieConsentBox() {
        var cookieConsentBoxFirstCol = $('<div></div>');
        var cookieConsentBoxSecondCol = $('<div></div>');

        var cookieConsentBoxHeadline = $('<h4>' + this.options.headline + '</h4>');
        var cookieConsentBoxText = $('<p>' + this.options.text + '</p>');
        var cookieConsentBoxTerms = $('<a href="' + this.options.termsurl + '" target="_blank">' + this.options.termstext + '</a>');

        var cookieConsentBoxAcceptButton = $('<button type="button" class="button button-primary button-accept">' + this.options.acceptbutton + '</button>');

        cookieConsentBoxHeadline.appendTo(cookieConsentBoxFirstCol);
        cookieConsentBoxText.appendTo(cookieConsentBoxFirstCol);
        cookieConsentBoxTerms.appendTo(cookieConsentBoxFirstCol);

        cookieConsentBoxAcceptButton.appendTo(cookieConsentBoxSecondCol);

        cookieConsentBoxFirstCol.appendTo(this.cookieConsentBox);
        cookieConsentBoxSecondCol.appendTo(this.cookieConsentBox);

        cookieConsentBoxAcceptButton.on('click', event => {
            this.setCookieConsentCookie();
            this.hideCookieConsentBox();
        });

        this.cookieConsentBox.addClass('active');
    }

    hideCookieConsentBox() {
        this.cookieConsentBox.removeClass('active');
    }

    setCookieConsentCookie() {
        Util.setCookie('cookieConsentAccepted', 'true', 365);
    }

    /**
     * Initializes cookie consent box
     */
    static initAll() {
        Util.initModule(CookieConsent.NAME, (element, config, name) => {
            const cookieConsent = new CookieConsent(element, config);
            cookieConsent.init();
        });
    }
}
