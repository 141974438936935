export default class Wrapper {
    static fromClassesInnerHtml(classes, innerHtml) {
    	const wrapper = new Wrapper;

        wrapper.classes = classes;
        wrapper.innerHtml = innerHtml;

    	return wrapper;
    }

    get element() {
        const element = document.createElement('div');
        this.classes.forEach(className => element.classList.add(className));
        element.innerHTML = this.innerHtml;
        return element;
    }
}
