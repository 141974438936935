export default class Element {
    constructor(config, ref) {
        this.config = config;
        this.ref = ref;
        /** @type HTMLElement */
        this.container = ref[0];

        if (!this.ref.attr('is-initialized')) {
            this.setInitialized(true);

            this.init();
            this.initListeners();
        }
    }

    setInitialized(value) {
        this.ref.attr('is-initialized', value ? 'true' : null);
    }

    init() {

    }

    initListeners() {

    }
}
