import {Element} from '../../../xlib/js/xlib';
import FormUtils from '../../../xlib/js/lib/FormUtils';

export default class NewsletterPluginForm extends Element
{
    init()
    {
        this.nssForm = $('form[name="nss-form"]', this.ref);
        this.submitButton = $('.is_submit_button', this.nssForm);
        this.errorWrapper = $('.nss_error_wrapper', this.ref);
    }

    initListeners()
    {
        this.nssForm.on('submit', (event) => {
            event.preventDefault();

            this.submitNewsletter(event.currentTarget);
        });

        $('input[name="error_url"]', this.nssForm).remove();
        $('input[name="success_url"]', this.nssForm).remove();
    }

    submitNewsletter(newsletterForm)
    {
        const submitPromise = FormUtils.submitFormUsingAjax(newsletterForm, 'json');
        submitPromise.then((response) => {
            this.redoCaptcha();

            if (!response.data || !response.data.result_code) {
                return;
            }

            const result_code = response.data.result_code;

            let errorText = result_code;
            if (this.config && this.config.translations && this.config.translations[result_code]) {
                errorText = this.config.translations[result_code];
            }

            const errorsHtml = []
            if (result_code === 'subscribed') {
                errorsHtml.push(`<div class="nss_success">${ errorText }</div>`);
            } else {
                errorsHtml.push(`<div class="nss_error">${ errorText }</div>`);
            }

            this.errorWrapper[0].innerHTML = errorsHtml.join("\n");
        });
    }

    redoCaptcha()
    {
        if (this.nssForm[0].FriendlyCaptchaAdapter) {
            this.nssForm[0].FriendlyCaptchaAdapter.redoCaptcha();
        }
    }
}
