export default class Template {
    static fromElementFallback(element, fallback) {
    	const template = new Template;

        template.element = element.first();
        template.fallback = fallback;

    	return template;
    }

    render(item) {
        if(!this.element.length) {
            return this.fallback(item);
        }

        const clonedElement = this.element.clone();
        $('.js_title', clonedElement).html(item?.title || item?.post_title);
        $('.js_url', clonedElement).attr('href', item.url);
        $('.js_intro', clonedElement).html(item.intro);
        $('.js_image', clonedElement).attr('src', item?.image);
        $('.js_name', clonedElement).html(item?.name);
        $('.js_name_alt', clonedElement).attr('alt', item?.name);
        $('.js_content', clonedElement).html(item?.content);
        $('.js_image_url', clonedElement).attr('src', item?.image?.url);
        $('.js_image_alt', clonedElement).attr('alt', item?.image?.alt);

        if(!item.image) {
            $('.js_if_image', clonedElement).remove();
        } else {
            $('.js_if_image', clonedElement).removeClass('d-none');
        }

        return clonedElement[0];
    }
}
