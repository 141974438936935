import _kebabCase from 'lodash/kebabCase';

export default class Util {
    /**
     * @param {Response} response
     * @return {Promise<any>}
     */
    static handleErrors(response) {
        if (!response.ok && response.status !== 404) {
            throw Error(response.statusText);
        }
        return response.json();
    }

    /**
     * @param {string} str
     * @returns {string}
     */
    static upperCaseFirstLetter(str) {
        return str.substr(0, 1).toUpperCase() + str.substr(1);
    }

    /**
     * @param {string} elementId
     * @returns {JSON}
     */
    static readJsonConfig(elementId) {
        const element = document.getElementById(elementId);
        let config;

        try {
            config = JSON.parse(element.innerText);
        } catch (error) {
            console.error(error);
        }

        return config;
    }

    /**
     * @param {Element} element
     * @param {string} name
     * @returns {JSON}
     */
    static readDatasetConfig(element, name) {
        [...Object.keys(element.dataset)].reduce((obj, key) => {
            const parts = _kebabCase(key).split('-');

            if (parts[0] === 'config' && parts[1] === name.toLowerCase() && parts[2]) {
                let value = element.dataset[key];

                if (value === 'true') {
                    value = true;
                } else if (value === 'false') {
                    value = false;
                } else if (!Number.isNaN(Number(value))) {
                    value = Number(value);
                }

                obj[parts[2]] = value;
            }

            return obj;
        }, {})
    }

    /**
     * @param {string} name
     * @param {function(HTMLElement, any, string)} fn
     */
    static initModule(name, fn) {
        const elements = document.querySelectorAll(`[data-modules*="${name}"]`);

        [...elements].forEach((element) => {
            let config = {};

            if (element.dataset.config && !!document.getElementById(element.dataset.config)) {
                config = Util.readJsonConfig(element.dataset.config);
            } else {
                config = Util.readDatasetConfig(element, name);
            }
            fn(element, config, name);
        });
    }

    /**
     * Sets (P)React state and returns a Promise with the new state object
     *
     * @param {any} stateObj
     * @returns {Promise<any>}
     */
    static promiseSetState(stateObj) {
        return new Promise((resolve, reject) => {
            if (!('setState' in this)) reject('Not a react component');

            this.setState(stateObj, () => resolve(this.state));
        });
    }

    /**
     * Delay a promise
     *
     * @param {number} [time]
     * @returns {Promise}
     */
    static delay(time = 2000) {
        return function (data) {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    resolve(data);
                }, time);
            });
        };
    }

    /**
     * Returns a random string of {n} length
     *
     * @param {number} [length]
     * @returns {string}
     */
    static getRandomString(length = 7) {
        return [...Array(length)].map(() => (~~(Math.random() * 36)).toString(36)).join('');
    }

    /**
     * Returns a random number
     *
     * @param {number} min
     * @param {number} max
     * @returns {number}
     */
    static getRandomInt(min, max) {
        const minRounded = Math.ceil(min);
        const maxRounded = Math.floor(max);

        return Math.floor(Math.random() * (maxRounded - minRounded)) + minRounded;
    }

    /**
     * Splits a comma-separated string into words
     *
     * @param {string} str
     * @param {string} [separator]
     * @returns {string[]}
     */
    static stringToArray(str, separator = ',') {
        str.split(separator)
            .map((subStr) => subStr.trim())
            .filter(Boolean);
    }

    /**
     * Read a cookie and its value
     *
     * @param {string} cname
     */
    static getCookie(cname) {
        var name = cname + '=';
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }

            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    /**
     * Set a new cookie
     *
     * @param {string} cname
     * @param {string} cvalue
     * @param {int} exdays
     */
    static setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    }

    /**
     * Display a human readable time format
     *
     * @param {string} date
     * @returns {string}
     */
    static humanDate(date) {
        var oldDate = new Date(date);
        var options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };

        return oldDate.toLocaleDateString('de-DE', options);
    }

    /**
     * fix conflict between lodash and underscore
     *
     * https://wpdevelopment.courses/articles/how-to-fix-activatemode-is-not-a-function-error-in-gutenberg/
     * https://stackoverflow.com/questions/63672622/typeerror-this-activatemode-is-not-a-function-gutenberg-wordpress
     */
    static fixLodashConflict() {
        let isLodash = false;

        // If _ is defined and the function _.forEach exists then we know underscore OR lodash are in place
        if ( 'undefined' != typeof( _ ) && 'function' == typeof( _.forEach ) ) {

            // A small sample of some of the functions that exist in lodash but not underscore
            const funcs = [ 'get', 'set', 'at', 'cloneDeep' ];

            // Simplest if assume exists to start
            isLodash  = true;

            funcs.forEach( function ( func ) {
                // If just one of the functions do not exist, then not lodash
                isLodash = ( 'function' != typeof( _[ func ] ) ) ? false : isLodash;
            } );
        }

        if ( isLodash ) {
            _.noConflict();
        }
    }
}
