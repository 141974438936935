import 'jquery';
import {Element} from '../../../xlib/js/xlib';
import axios from 'axios';
import Template from '../Template';
import Wrapper from '../Wrapper';

export default class SearchResultsElement extends Element {
    init() {
        this.sectionsConfig = this.config.sectionsConfig;

        this.loadMoreButtons = $('.infinite-scrolling-more', this.ref);

        this.productsWrapper = $('.products-wrapper', this.ref);
        this.productsTemplate = Template.fromElementFallback(
            $('.js_template', this.productsWrapper),
            product => Wrapper.fromClassesInnerHtml(['col-md-6', 'col-lg-3'], `
                    <div class="teaser teaser--image teaser--small teaser--product">
                        <a href="${ product.url }" class="teaser__img">
                            <img src="${ product.image.url }" loading="lazy" alt="${ product.image.alt }">
                        </a>
                        <div class="teaser__inner">
                            <div class="teaser__title">
                                ${ product.title }
                            </div>

                            <a href="${ product.url }" class="btn btn-primary"><span class="btn__text">zum Produkt</span></a>
                        </div>
                    </div>
                `).element
        );

        this.expertsWrapper = $('.experts-wrapper', this.ref);
        this.expertsTemplate = Template.fromElementFallback(
            $('.js_template', this.expertsWrapper),
            expert => {

                let imageHtml = '';
                let expertClass = '';
                if (expert.image) {
                    imageHtml = `
                        <span class="teaser__img">
                            <img src="${ expert.image }" loading="lazy" alt="${ expert.name }" width="240"/>
                        </span>
                    `;
                    expertClass = 'teaser--img';
                }

                return Wrapper.fromClassesInnerHtml(['col-md-6', 'col-lg-4', 'teaser-col'], `
                    <a href="${ expert.url }" class="teaser ${ expertClass }">
                        ${ imageHtml }
                        <div class="teaser__inner">
                            <div class="teaser__title">
                                <span>
                                    ${ expert.name } <span class="link__arrow"></span>
                                </span>
                            </div>
                            <div class="teaser__short">
                                ${ expert.content ?? '' }
                            </div>
                        </div>
                    </a>
                `).element
            }
        );

        this.articlesWrapper = $('.articles-wrapper', this.ref);
        this.articlesTemplate = Template.fromElementFallback(
            $('.js_template', this.articlesWrapper),
            article => Wrapper.fromClassesInnerHtml(['article__item'], `
                    <a href="${ article.url }" class="article__title">${ article.post_title || article.title }</a>
                    <div class="article__subtitle">${ article.intro ?? '' } </div>
                `).element
        );

        this.nextSite = {};
        for (const $sectionName of Object.keys(this.sectionsConfig)) {
            this.nextSite[$sectionName] = 2;
        }
    }

    initListeners() {
        /* Searchbox suggest - remove */
        this.loadMoreButtons.on('click', (event) => {
            this.loadMorePosts(event.currentTarget);
        });
    }

    loadMorePosts(loadMoreButton) {
        const notLoading = !loadMoreButton.classList.contains('loading');
        if (notLoading) {
            loadMoreButton.classList.add('loading');

            const searchQuery = encodeURIComponent(this.config.searchQuery);
            const pageName = encodeURIComponent(this.config.pageName);
            const sectionName = loadMoreButton.getAttribute('data-section-name');
            const page = this.nextSite[sectionName];
            const searchResponse = axios.get(
                `/api/Reichweiten/suche/mehr/?page_name=${pageName}&search=${searchQuery}&post_type=${sectionName}&page_no=${page}`,
                {
                    responseType: 'json'
                }
            );
            searchResponse.then((response) => {
                if (response.data && response.data.articles) {
                    this.appendArticles(response.data.articles);
                    if (!response.data.articles.has_more) {
                        $(loadMoreButton).hide();
                    }
                }
                if (response.data && response.data.experts) {
                    this.appendExperts(response.data.experts);
                    if (!response.data.experts.has_more) {
                        $(loadMoreButton).hide();
                    }
                }
                if (response.data && response.data.products) {
                    this.appendProducts(response.data.products);
                    if (!response.data.products.has_more) {
                        $(loadMoreButton).hide();
                    }
                }

                this.nextSite[sectionName] += 1;
                loadMoreButton.classList.remove('loading');
            });
        }
    }

    appendProducts(products) {
        if (!products.documents) {
            return;
        }

        products.documents.map(product => this.productsTemplate.render(product))
            .forEach(element => this.productsWrapper[0].appendChild(element));
    }

    appendExperts(experts) {
        if (!experts.documents) {
            return;
        }
        experts.documents
            .map( expert => this.expertsTemplate.render(expert) )
            .forEach(element => this.expertsWrapper[0].appendChild(element));
    }

    appendArticles(articles) {
        if (!articles.documents) {
            return;
        }

        articles.documents
            .map( article => this.articlesTemplate.render(article) )
            .forEach(element => this.articlesWrapper[0].appendChild(element));
    }
}
