import $ from 'cash-dom';
import {Element} from "plugins/xlib/js/xlib";
import axios from "axios";

export default class HeaderElement extends Element {
    init() {
        this.headerSearch = $('.header__search', this.ref);
        this.openSearch = $('#SearchOpen', this.ref);
        this.closeSearch = $('#SearchClose', this.ref);
        this.searchInput = $('.header__search__form-control', this.ref);
        this.searchSuggestions = $('#suggest', this.ref);
        this.suggestions = $('.suggestions-wrapper', this.ref);
        this.submitSpan = $('form span[type=submit]', this.ref);
    }

    initListeners() {
        this.openSearch.on('click', (event) => {
            this.headerSearch.addClass('show');
            this.updateSuggestions();
            // this.openSearch.addClass('open');
            $('body').addClass('search_open');
            this.searchInput[0].focus();
        });
        this.closeSearch.on('click', (event) => {
            this.headerSearch.removeClass('show');
            // this.openSearch.removeClass('open');
            $('body').removeClass('search_open');
        });

        /* Searchbox suggest - remove */
        this.searchInput.on('keyup', (event) => {
            this.updateSuggestions();
        });

        this.submitSpan.on('click', (event) => {
            $(event.currentTarget).closest('form').submit();
        });
    }

    updateSuggestions() {
        const searchQuery = encodeURIComponent(this.searchInput.val());
        const isLoading = this.searchSuggestions[0].classList.contains('loading');
        if (searchQuery && this.headerSearch.hasClass('show') && !isLoading) {
            this.searchSuggestions[0].classList.add('loading');
            const searchResponse = axios.get(`/api/Reichweiten/suche/mehr/?page_name=suggestions&search=${searchQuery}`, {
                responseType: 'json',
            });
            searchResponse.then((response) => {
                this.searchSuggestions[0].classList.remove('loading');
                if (searchQuery !== encodeURIComponent(this.searchInput.val())) {
                    this.updateSuggestions();
                } else {
                    this.suggestions[0].innerHtml = '';

                    if (response.data) {
                        this.renderSuggestions(response.data);
                    }
                }
            });

            this.searchSuggestions.show();
        } else {
            this.searchSuggestions.hide();
        }
    }

    renderSuggestions(suggestions) {

        const articleSuggestionsHtml = [];
        const foundArticles = suggestions.articles && suggestions.articles.documents && suggestions.articles.documents.length;
        if (foundArticles) {
            suggestions.articles.documents.forEach((article) => {
                articleSuggestionsHtml.push(`
                    <li class="suggest__item">
                        <a href="${article.url}">${article.post_title}</a>
                    </li>
                `);
            });
        }

        const productSuggestionsHtml = [];
        const foundProducts = suggestions.products && suggestions.products.documents && suggestions.products.documents.length;
        if (foundProducts) {
            suggestions.products.documents.forEach((product) => {
                product.image = product.image || {};

                productSuggestionsHtml.push(`
                    <a href="${ product.url }" class="suggest__product">
                        <div class="row">
                            <div class="col-5">
                                <div class="product__img img--full">
                                    <img src="${ product.image.url || '' }" alt="${ product.image.alt }">
                                </div>
                            </div>
                            <div class="col-7">
                                <div class="product__content">
                                    ${ product.title }
                                </div>
                            </div>
                        </div>
                    </a>
                `);
            });
        }

        const expertSuggestionsHtml = [];
        const foundExperts = suggestions.experts && suggestions.experts.documents && suggestions.experts.documents.length;
        if (foundExperts) {
            suggestions.experts.documents.forEach((expert) => {
                expertSuggestionsHtml.push(`
                    <a href="${ expert.url }" class="suggest__expert">
                        <div class="row">
                            <div class="col-5">
                                <div class="expert__img">
                                    <img src="${ expert.image || '' }" alt="${ expert.name }">
                                </div>
                            </div>
                            <div class="col-7">
                                <div class="expert__content">
                                    ${ expert.name }
                                </div>
                            </div>
                        </div>
                    </a>
                `);

            });
        }

        const suggestionsHtml = [];

        const articleCols = foundProducts || foundExperts ? 6 : 12;
        suggestionsHtml.push(`
            <div class="col-md-${ articleCols }">
                <div class="suggest__title">Artikel</div>
                <ul class="suggest__list suggest-articles-wrapper">
                    ${ articleSuggestionsHtml.join("\n") }
                </ul>
            </div>
        `);

        if (foundProducts || foundExperts) {
            suggestionsHtml.push(`
                <div class="col-md-6">
            `);

            if (foundProducts) {
                suggestionsHtml.push(`
                    <div class="suggest__title">Produkte</div>
                    <div class="suggest__products">
                        <div class="suggest-products-wrapper">
                            ${ productSuggestionsHtml.join("\n") }
                        </div>
                    </div>
                `);
            }

            if (foundExperts) {
                suggestionsHtml.push(`
                    <div class="suggest__title">Experten</div>
                    <div class="suggest__experts">
                        <div class="suggest-experts-wrapper">
                            ${ expertSuggestionsHtml.join("\n") }
                        </div>
                    </div>
                `);
            }

            suggestionsHtml.push(`
                </div>
            `);
        }

        this.suggestions[0].innerHTML = suggestionsHtml.join("\n");
    }
}
