import $ from 'cash-dom';

export default class Application {
    static configure(config) {
        if (Array.isArray(config.Elements)) {
            console.log('Warning: please use associative Elements with HTML attributes as keys');

            let elementsByAttributes = {};
            config.Elements.forEach((xlibElement) => {
                const elementAtttibute = this.elementClassNameToAttribute(xlibElement.name);
                elementsByAttributes[elementAtttibute] = xlibElement;
            });
            config.Elements = elementsByAttributes;
        }
        this.config = config;
    }

    static elementClassNameToAttribute(importName) {
        const elementClassName = importName.split(/_/)[1] ? importName.split(/_/)[1] : importName;
        const elementFileName = elementClassName.replace(/Element$/, '');
        return 'is-element-'+ elementFileName.split(/(?=[A-Z])/).join('-').toLowerCase();
    }

    static start() {
        $(() => {
            Application.loadElements($(document.body));
        });
    }

    static loadElements(ref) {
        const possibleElements = $('[is-element]', ref);

        for (let i = 0; i < possibleElements.length; i++) {
            const possibleElement = possibleElements[i];

            if (possibleElement.attributes['is-initialized']) {
                // already initialized this element
                continue;
            }

            for (let j = 0; j < possibleElement.attributes.length; j++) {
                const attribute = possibleElement.attributes[j];

                if (attribute.name.match(/^is-element-/i)) {
                    const elementClass = Application.getElementFromAttributeName(attribute.name);

                    if (elementClass) {
                        let config = attribute.value || '{}';
                        try {
                            config = decodeURIComponent(config);
                            config = JSON.parse(config);
                        } catch (e) {
                            config = {};
                            console.error("Failed to parse config for " + elementClass.name);
                        }
                        new elementClass(config, $(possibleElement));
                    }
                }
            }
        }
    }

    static getElementFromAttributeName(elementAttribute) {
        if (this.config.Elements[elementAttribute]) {
            return this.config.Elements[elementAttribute];
        } else {
            console.error('No element for found for attribute ' + elementAttribute);
            return null;
        }

    }
}
